import React, {useContext, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import {app} from '../firebase-config'
import parse from 'html-react-parser';


export default function StoryPage() {

  const {firebaseUser} = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const arr = Array.from(Array(12).keys());

  useEffect(() => {
    setLoading(true);
    const getTitle = app.firestore().collection(`storiesv2`).doc(id);
    getTitle.onSnapshot((querySnapShot) => {
      let storyDoc = querySnapShot.data()
      setTitle(storyDoc.title);
      if(storyDoc.freeContent) {
        setLoading(false);
        parseStory(storyDoc.freeContent);
      } else {
        const getContent = app.firestore().collection(`storiesv2`).doc(id).collection('auth').doc('content');
        getContent.onSnapshot((querySnapShot) => {
          setLoading(false);
          let contentDoc = querySnapShot.data();
          parseStory(contentDoc.content);
        }, error => {
          console.log(error)
          setError(true);
          setLoading(false);
        }); 
      }
    });  
  }, [])

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  const parseStory = (story) => {
    let parsedContent = story.replace(/\<p\>\*\*\*\<\/p\>/g, '<p className="py-10 text-center">***</p>');
    setContent(parsedContent);
  }

  useEffect(() => {
    window.scrollTo (0,0);
  }, [])

  let { id } = useParams();
  return (
    <div>
      {
        (loading ? (<div className="story-page container relative md:py-36 text-5xl text-white">
          <div className="skeleton w-64 opacity-20 h-12 rounded-full m-auto"></div>
          <div className="skelly-paragraph mt-12">
            {
              arr.map((val, index) => 
                <div key={index} className="skeleton opacity-20 w-2/3 h-8 mb-4 rounded-full m-auto"></div>
              )
            }
        </div>
        </div>) : 
        <div className="story-page container relative py-10">
        {!error ? ( 
        <div>
          <h1 className="story-title text-white py-10 md:p-16 md:mt-10 uppercase text-2xl md:text-5xl">{title}</h1>
          <div className="story-body text-gray-300 text-left text-white md:px-24 px-5 text-l mb-4 pb-6 md:pb-32">
              {parse(content)}
          </div>
          <div className="back-to-top-wrapper">
            <a onClick={scrollToTop} className="btn text-white back-to-top-link cursor-pointer">Back to top</a>
          </div>
        </div>) :(
          <div>
            <h2 className="text-3xl text-white pt-20 mb-8">Sorry, only holders of my NFT have access to this story! </h2>
            <p className="text-xl text-white opacity-80">Connect your wallet and make sure you are on the right address.</p>
          </div>
        )}
      </div>)
      }
    </div>)
}
