import './App.scss';
import Web3Modal from "web3modal";
import {useState, useEffect} from 'react'
import Header from './components/Header';
import Hero from './components/Hero';
import Consent from './components/Consent';
import StoryPage from './components/StoryPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { setPersistence, browserSessionPersistence } from 'firebase/auth';
import { app, db, auth } from './firebase-config';
import  { providers } from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import 'react-tooltip/dist/react-tooltip.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import StoryPageInteractive from './components/StoryPageInteractive';

import axios from 'axios';
import {AuthContext} from './contexts/AuthContext';

import {ethers} from 'ethers'


const providerOptions = {
  // coinbasewallet: {
  //   package: CoinbaseWalletSDK,
  //   options: {
  //     appName: "Blockhead.press",
  //     infuraId: "cf702e9445fd4395b9108b3ab032cb10"
  //   }
  // }, 
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "cf702e9445fd4395b9108b3ab032cb10",
    }
  }
}


function App() {

  const [web3User, setWeb3User] = useState(null);
  const [userNFTData, setUserNFTData] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [consent, setConsent] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [signatureRefused, setSignatureRefused] = useState(false);


  const [unsigned, setUnsigned] = useState(true);

  let verifying = false;
  

  const handleResize = () => {
    if (window.innerWidth < 768) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  async function connectWallet(skipVerify = false) {

    if (!window.ethereum && !isMobile) {
      providerOptions['custom-metamask'] = {
        display: {
          logo: providers.METAMASK.logo,
          name: 'Install MetaMask',
          description: 'Connect using browser wallet'
        },
        package: {},
        connector: async () => {
          window.open('https://metamask.io')
          throw new Error('MetaMask not installed');
        }
      }
    }
    try {
      let web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
      })
      const web3ModalInstance = await web3Modal.connect();
      const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
      // web3ModalInstance.on("accountsChanged", (accounts) => {
      //   app.auth().signOut();
      //   setFirebaseUser(null);
      //   setWeb3User(accounts[0]);
      // });
   
      if(web3ModalProvider) {
        setWeb3User(web3ModalProvider.provider.selectedAddress);
        localStorage.setItem('acc', web3User);

        if(!firebaseUser && !skipVerify) {
          console.log(`connect`)
          verifyUser(web3ModalProvider.provider.selectedAddress);
        }
      }

    } catch(error) {
      console.error(error);
    }
  }

  function toHex(stringToConvert) {
    return stringToConvert
      .split('')
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
      .join('');
  }

  async function verifyUser(address) {
    setTimeout(async () => {
      console.log(verifying);
      setLoading(true);
      setSignatureRefused(true);
      let nonceSigned;
      try {
        let res = await axios.post(`${process.env.REACT_APP_CLOUDFUNCURL}/api/getNonce`, { address })
        let signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
        setUnsigned(true);
        nonceSigned = await signer.signMessage(res.data.nonce);
        setUnsigned(false);
        setSignatureRefused(false);
        verifying = false;

      } catch (err) {
        setLoading(false);
        setSignatureRefused(true);
        verifying = false;
        return;
      }
      let response = null;
       try{
        let response = await axios.post(`${process.env.REACT_APP_CLOUDFUNCURL}/api/verifyUser`, { address, signature: nonceSigned })
        setPersistence(app.auth(), browserSessionPersistence).then(() => {
          app.auth().signInWithCustomToken(response.data.token).then(res => {
            setLoading(false);
            setFirebaseUser(res.user);
            refreshUserData(res.user.uid);
            setVerificationError(false);
            db.collection("stories").get().then((querySnapShot) => {
              const saveFirebaseStories = [];
              querySnapShot.forEach((doc) => {
                saveFirebaseStories.push(doc.data());
              });
            });  
            })
        })
      } catch {
        setVerificationError(true);
        setLoading(false);
       }
    })
  }

  async function refreshUserData(uid) {
    const userData = app.firestore().collection(`users`).doc(uid);
          userData.onSnapshot((querySnapShot) => {
            let userDataDoc = querySnapShot.data();
            setUserNFTData(userDataDoc);
    });  
  }

  async function initWeb3Connection() {
    let web3Modal = new Web3Modal({
      providerOptions,
    })
    const web3ModalInstance = await web3Modal.connect();
    const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
 
    if(web3ModalProvider) {
      setWeb3User(web3ModalProvider.provider.selectedAddress);
    }
  }

  useEffect(() => {

    window.sessionStorage.getItem("blockheadConsent") ? setConsent(true) : setConsent(false);

    // if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")){
    //   initWeb3Connection();
    // };

    if(localStorage.getItem('acc')) {
      connectWallet(true);
    }

   
    if(window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        console.log('accountsChanged')
        app.auth().signOut();
        setFirebaseUser(null);
        setWeb3User(accounts[0]);
        setTimeout(() => {
          if(verifying) {
            return;
          }
          verifying = true;
          console.log(`state`,verifying);
          verifyUser(accounts[0]);
        })
      })
    }

    window.addEventListener("resize", handleResize)

    const getFromFirebase = app.firestore().collection("stories");
    getFromFirebase.onSnapshot((querySnapShot) => {
      const saveFirebaseTodos = [];
      querySnapShot.forEach((doc) => {
        saveFirebaseTodos.push(doc.data());
      });
    }); 
  }, [])

  return (
    <BrowserRouter>
    <AuthContext.Provider value={{web3User, setWeb3User, firebaseUser, setFirebaseUser, userNFTData, setUserNFTData}}>
      <div className="App relative h-full">
        <Header setLoading={setLoading} web3User={web3User} connectWallet={connectWallet}/>
        {/* { loading && (<div className="spinner-overlay"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>) } */}
          <Routes>
            <Route exact path="/" element={<Hero unsigned={unsigned} signatureRefused={signatureRefused} verifyUser={verifyUser} verificationError={verificationError} connectWallet={connectWallet} loading={loading}/>} />
            <Route path="/story/:id" element={<StoryPage/>} />
            <Route path="/story/interactive/:id" element={<StoryPageInteractive/>} />
          </Routes>
          {!consent && <Consent setConsent={setConsent}/>}
      </div>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
