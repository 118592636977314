import React, {useContext} from 'react'
import Modal from 'react-modal';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext'
import { app } from '../firebase-config';

const customStyles = {
    content: {
      zIndex: '9000',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      minWidth: '400px',
      padding: '',
      border: '0px',
      borderRadius: '20px',
      marginRight: '-50%',
      backgroundColor: '#0c0c10',
      transform: 'translate(-50%, -50%)',
    },
  };

    const emptyValue = { 
        email: '',
        country: '',
        streetAddress: '',
        state: '',
        city: '',
        apartment: '',
        zip: '',
        packageName: '',
    }

    const emptyError = { 
        email: '',
        country: '',
        streetAddress: '',
        state: '',
        city: '',
        apartment: '',
        zip: '',
        packageName: '',
    }

function ClaimForm({modalIsOpen, modalSetter}) {

    const {web3User, firebaseUser, userNFTData, setUserNFTData} = useContext(AuthContext);
    const [values, setValues] = React.useState({...emptyValue});
    const [formError, setFormError] = React.useState('');
    const [errors, setErrors] = React.useState({...emptyError});
    const [claimPending, setClaimPending] = React.useState(false);
    const [claimSuccess, setClaimSuccess] = React.useState(false);

    const handleChange = (e) => {
        setFormError('');
        values[e.target.id] = e.target.value;
        setTimeout(() => {
            setValues(values)
        })
        
    }

    function closeModal() {
        setClaimSuccess(false);
        setValues({...emptyValue});
        setFormError('');
        setErrors({...emptyValue});
        modalSetter(false);
    }

    const submitForm = (e) => {
        setFormError('');
        e.preventDefault()
        let isValid = true;
        validateAll();
        Object.values(errors).forEach(error => {
            if(error.length) {
                isValid = false;
            }
        })
        
        if (!isValid) {
            console.log(`invalid`)
            return false;
        }
        sendClaim();
    }

    async function sendClaim() {
        try {
            setClaimPending(true);
            let body = {...values};
            console.log(body)
            let res = await app.firestore().collection('claim-requests').add(body);
            await axios.post(`${process.env.REACT_APP_CLOUDFUNCURL}/api/claim-request`, {...body, claimId: res.id, wallet: web3User});
            setClaimSuccess(true);
            setClaimPending(false);
          } catch (err) {
            setClaimPending(false);
            setFormError('Claim failed, please make sure you are a Good Griefs NFT holder.')
            return;
          }
    }

    const validateAll = () => {
        setFormError('');
        Object.keys(values).forEach(key => {
            validateOne({target: {value: values[key], id: key}});
        })
    }

    const changed = e => {
        handleChange(e)
        validateOne(e)
    }

    const checkEmail = (e) => {
        let message = '';
        validateOne(e);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValidEmail = emailRegex.test(e.target.value);
        if(!isValidEmail) {
            message = 'Please enter a valid e-mail address';
        }
        errors[e.target.id] = message;
        setTimeout(() => {
            setErrors({...errors});
        })
    }

    const validateOne = (e) => {

        setFormError('');
        let key = e.target.id;
        let value = e.target.value;
        let message = '';
        if(key == 'apartment') {
            return;
        }
       
        if (!value && key !== 'apt') {
            message = `${key} is required`;
        }
        
        errors[key] = message;
        setTimeout(() => {
            setErrors({...errors});
        })
       
    }

  return (
    <div> 
        <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        overlayClassName="modal-overlay"
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
             <div className="flex-col modal-content flex pt-24 px-6 md:px-10 py-20 md:py-6 md:pt-10 text-white align-center justify-center">
             {claimSuccess && 
                <div>
                    <div className="p-10">
                        <p className="text-xl text-gray-200">Mailing address submitted. Your book will be sent shortly.</p>
                    </div>
                    <div className="flex items-center justify-end gap-8 mt-6">
                        <button className="btn-mint" onClick={closeModal}>OK</button>
                    </div>
                </div>    
            }
             {!claimSuccess && 
             <form method="post" name="userRegistrationForm">
                <div className="claimForm">
                <div className="md:flex md:flex-row justify-between gap-6 w-full"> 
                    <div className="mb-4">
                        <label className="form-label">
                            E-mail
                        </label>
                        <input  onChange={changed} onBlur={checkEmail} className="form-input" id="email" type="text"/>
                        <div className="validation-msg">{errors['email']}</div>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">
                            Country
                        </label>
                        <input onChange={changed} onBlur={validateOne} className="form-input" id="country" type="text"/>
                        <div className="validation-msg">{errors['country']}</div>
                    </div>
                </div>
                <div className="mb-4">
                        <label className="form-label">
                            Street address
                        </label>
                        <input onChange={changed} onBlur={validateOne} className="form-input" id="streetAddress" type="text"/>
                        <div className="validation-msg">{errors['streetAddress']}</div>
                    </div>
                <div className="md:flex md:flex-row justify-between gap-6 w-full">    
                    <div className="mb-4">
                        <label className="form-label">
                            State
                        </label>
                        <input onChange={changed} onBlur={validateOne} className="form-input" id="state" type="text"/>
                        <div className="validation-msg">{errors['state']}</div>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">
                            City
                        </label>
                        <input onChange={changed} onBlur={validateOne} className="form-input" id="city" type="text"/>
                        <div className="validation-msg">{errors['city']}</div>
                    </div>
                </div>
                <div className="md:flex md:flex-row justify-between gap-6 w-full">
                    <div className="mb-4">
                        <label className="form-label">
                            Apartment, suite, etc. (optional)
                        </label>
                        <input onChange={changed} onBlur={validateOne} className="form-input" id="apartment" type="text"/>
                        <div className="validation-msg">{errors['apt']}</div>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">
                            ZIP/postal code
                        </label>
                        <input onChange={changed} onBlur={validateOne} className="form-input" id="zip" type="text"/>
                        <div className="validation-msg">{errors['zip']}</div>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="form-label">
                        Name for package
                    </label>
                    <input onChange={changed} onBlur={validateOne} className="form-input" id="packageName" type="text"/>
                    <div className="validation-msg">{errors['packageName']}</div>
                </div>
                <div className="validation-msg">{formError}</div>
                </div>
                <div className="flex claim-btns items-center justify-end gap-8 mt-6">
                    <a className="inline-block align-baseline text-sm text-gray-200 hover:text-blue-800" onClick={closeModal} href="#">
                        Cancel
                    </a>
                    {
                        !claimPending ? <button onClick={submitForm} className="btn-mint">Claim</button> : <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    }
                </div>
              </form>}
             </div>
            
        </Modal>

    </div>
  )
}

export default ClaimForm