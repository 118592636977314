import React from 'react'

function Consent({setConsent}) {
  return (
    <div className="w-full z-50 consent-box-wrapper fixed flex items-center justify-center bottom-8">
        <div className="bg-black gap-4 flex consent-box md:flex-row flex-col rounded-md md:w-3/4 items-center align-center p-6 mx-4">
            <p className="text-white text-left pr-4">
                These stories are works of fiction. All the names, characters, businesses, places, events and incidents in this book are either the product of the author’s
                imagination or used in a fictitious manner. Any resemblance to actual persons, living or dead, or actual events is purely coincidental.
            </p>
            <button className="btn text-white h-10" onClick={(event) => {setConsent(true);  window.sessionStorage.setItem("blockheadConsent", true);}}>OK</button>
        </div>
    </div>
  )
}

export default Consent