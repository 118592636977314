import React, {useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import MintModal from './MintModal';



function StoryCardBanner({index, story}) {
    const history = useNavigate()
    const {firebaseUser} = useContext(AuthContext);
    const {userNFTData} = useContext(AuthContext);

    //opacity-60 pointer-events-none grayscale

    const showStory = (event, index) => {
      if(story.interactive) {
        history(`/story/interactive/${index}`);
      } else {
        history(`/story/${index}`);
      }
    }

    useEffect(() => {
    }, [userNFTData])

  return (
    <div className={"text-white rounded-xl relative md:m-6 m-6 story-card img-hover-zoom" + ((!firebaseUser && story.price > 0 || !story.released) ? " opacity-60 pointer-events-none grayscale" : "")} style={{ 
        backgroundImage: `url(${story.banner})` 
      }}>
        <div className="story-fade absolute w-full h-full rounded-xl">
            { !story.released &&
              <div className="coming-soon absolute mt-2 mr-2 rounded-3xl top-0 py-2 w-32 right-0 z-10">
                  <p className='text-l z-100 opacity-100'>Coming soon</p>
              </div>
            }
            <div className="absolute w-full bottom-0 mb-5">
              <div className="story-card-title text-xl text-white text-gray-200 pb-2">{story.title}</div>
              <div className="text-gray-200 text-xs px-4 pb-2">{story.description}</div>
              { story.released &&
                <MintModal showStory={(event) => showStory(event, index)} price={story.price} title={story.title} index={index}/>
              }
              {/* <div className="mt-2">
                {firebaseUser &&
                <button onClick={ event => handleStory(event, index, price)} className="btn-mint">{(userNFTData && userNFTData.stories[index]) ? `Read` : `Mint for ${price}Ξ`}</button>
                }
              </div> */}
            </div>
        </div>
    </div>
  )
}

export default StoryCardBanner