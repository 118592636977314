import React, {useEffect, useState} from 'react'
import {app} from '../firebase-config'
import { useParams } from 'react-router-dom'

function StoryPageInteractive() {
    
    const [loading, setLoading] = useState(false);
    const [storyDoc, setStoryDoc] = useState({});
    const [error, setError] = useState(false);
    const [showTitle, setShowTitle] = useState(true);
    const arr = Array.from(Array(12).keys());
    const [height, setHeight] = React.useState("0px");

    function addCSS() {
        var cssLink = document.createElement("link");
        cssLink.href = "Interactive.css"; 
        cssLink.rel = "Stylesheet"; 
        cssLink.type = "text/css"; 
        document.getElementById("interactive-iframe").contentDocument.head.appendChild(cssLink);
    }

    let { id } = useParams();

    function setupIframe() {
        addCSS();
        setLoading(true);
        const getStory = app.firestore().collection(`storiesv2`).doc(id);
        let iframe = document.getElementById('interactive-iframe');
        getStory.onSnapshot((querySnapShot) => {
            let storyDoc = querySnapShot.data();
            let innerDoc = iframe.contentDocument || iframe.contentWindow.document;
       
            if(storyDoc.freeContent) {
                innerDoc.getElementById('story-content').insertAdjacentHTML( 'beforeend', storyDoc.freeContent );
                setStoryDoc(storyDoc);
                let storyScript = document.createElement("script");
                storyScript.title = "Twine engine code";
                storyScript['data-main'] = "harlowe";
                storyScript.src = "./storyCode.js";
                console.log(innerDoc)
                innerDoc.body.appendChild(storyScript);
                setTimeout(() => {
                    let slides =  innerDoc.getElementsByTagName('tw-passage');
                    console.log(slides)
                    if(slides.length) {
                        slides[0].classList.add('first-slide');
                        let sidebar = innerDoc.getElementsByTagName('tw-sidebar');
                        if(sidebar.length) {
                            sidebar[0].remove();
                        }
                    }
                    innerDoc.addEventListener("mouseup", (event)=> {
                        if(event.target.localName == 'tw-link' || event.target.localName == 'tw-icon') {
                            setShowTitle(false);
                            window.scrollTo(0,0);
                            setTimeout(()=> {
                               setDynamicIframeHeight(innerDoc);
                            }, 200)
                        }
                    })
                    setDynamicIframeHeight(innerDoc)
                    setLoading(false);
                }, 1000)

            }
        });  
    }

    function setDynamicIframeHeight(innerDoc) {
        setTimeout(() => {
            let slide = innerDoc.getElementsByTagName('tw-story');
            let newHeight = slide[0].clientHeight > 800 ? slide[0].clientHeight : 2400;
            setHeight(newHeight+'px');
        }, 500)
    }
    
    useEffect(() => {
        window.scrollTo(0,0);
        setHeight('1500px')
        sessionStorage.setItem('Saved Session', '');
    },[])

    return (
        <div>
                <div className="story-page container mt-36">
                {!error ? ( 
                <div>
                {showTitle && <h1 className="story-title interactive-title text-gray-300 pt-16 px-6 mt-12 md:mt-12 uppercase text-2xl md:text-4xl">{storyDoc.title}</h1>}
                <div className="story-body-interactive text-gray-400 text-left md:px-24 px-5 text-l mb-4 pb-6 md:pb-32">
                    {loading && (<div className="story-page container relative md:py-36 text-5xl text-gray-400">
      
                    <div className="skelly-paragraph mt-16">
                        {
                        arr.map((val, index) => 
                            <div key={index} className="skeleton opacity-20 w-2/3 h-8 mb-4 rounded-full m-auto"></div>
                        )
                        }
                    </div>
                    </div>)}
                    <div id="iframe-container"/>
                        <iframe  sandbox="allow-scripts allow-popups allow-same-origin" scrolling='no'  height={height}  id='interactive-iframe' onLoad={setupIframe} className='w-full' src={`${process.env.PUBLIC_URL}/IRL.html`}></iframe>
                    </div>
                    
                </div>) :(
                <div>
                    <h2 className="text-3xl text-white pt-20 mb-8">Sorry, only holders of my NFT have access to this story! </h2>
                    <p className="text-xl text-white opacity-80">Connect your wallet and make sure you are on the right address.</p>
                </div>
                )}
            </div>
        </div>
    )
}

export default StoryPageInteractive