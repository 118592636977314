import React, {useEffect, useState} from 'react'
import StoryCard from './StoryCard'
import StoryCardBanner from './StoryCardBanner'
import { app } from '../firebase-config';
import StoryCardSkeleton from './StoryCardSkeleton';
import ArtBookClaim from './ArtBookClaim';


export default function Stories() {

  const [stories, setStories] = useState([]);
  const arr = Array.from(Array(4).keys());
  const [error, setError] = useState(null);

  useEffect(() => {
    setStories([]);
      const getFromFirebase = app.firestore().collection("storiesv2");
      getFromFirebase.onSnapshot((querySnapShot) => {
        let resStories = [];
        querySnapShot.forEach((doc) => {
          resStories.push(doc.data());       
        });
        resStories.reverse();
        setError(null);
        setStories(resStories);
      }, error => {
        setError(error.toString())
      });  
  }, [])

  return (
    <div className="flex flex-1 container w-full">
        {(!error ? (
        <div className="story-grid w-full">
          {stories.length > 0 ? (
            stories.map((story, index) => 
              <StoryCardBanner key={index} story={story} index={story.storyID}></StoryCardBanner>
              )
            ) : (
              arr.map((val, index) => 
                <StoryCardSkeleton key={index}></StoryCardSkeleton>
              )
            )}
             {/* <ArtBookClaim></ArtBookClaim> */}
        </div>) : (
          <div className="w-full bg-gray-900 py-8">
            <h2 className="text-white text-3xl">Well, that doesn't look good.</h2>
            <p className="text-white text-xl my-6">Here's the error if it helps: <br/><span className="text-red-300">{error}</span></p>
            <p className="text-white text-xl">{'Try reloading the page or come back later idk :('}</p>
          </div>
        ) 
        )}
    </div>
  )
}
