import React from 'react'

function StoryCardSkeleton() {
  return (
    <div className="border border-gray-200 p-4 m-6 md:p-9 md:m-6 rounded-xl relative opacity-60">
        <div className="story-fade absolute w-full h-full rounded-xl"> </div>
        <div className="skeleton w-2/3 h-8 rounded-xl m-auto"></div>
        <div className="skelly-paragraph mt-6">
            <div className="skeleton w-full h-4 mb-4 rounded-xl m-auto"></div>
            <div className="skeleton w-full h-4 mb-4 rounded-xl m-auto"></div>
            <div className="skeleton w-full h-4 mb-4 rounded-xl m-auto"></div>
        </div>
        <div className="skeleton story-btn w-1/3 h-9 rounded-3xl m-auto opacity-60"></div>
    </div>
  )
}

export default StoryCardSkeleton