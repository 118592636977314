import React, {useContext, useState} from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { AuthContext } from '../contexts/AuthContext';
import { app, db } from '../firebase-config';
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation } from 'react-router-dom'
import Modal from 'react-modal';
import TwitterIcon from '@mui/icons-material/Twitter';
import amazon from '../assets/amazon.png'

export default function Header({setLoading, connectWallet}) {

  const history = useNavigate()

  const {setWeb3User, web3User} = useContext(AuthContext);
  const {setFirebaseUser} = useContext(AuthContext);
  const [showHamburger, setShowHamburger] = useState(false); 
  const [showAboutMe, setShowAboutMe] = useState(false);

  function signOut() {
    app.auth().signOut();
    setWeb3User(null);
    setFirebaseUser(null);
    setShowHamburger(false);
    setLoading(false);
    history(`/`);
    localStorage.removeItem('acc');
  }

  function openModal() {
    setShowAboutMe(true);
  }
  function closeModal() {
    setShowAboutMe(false);
  }

  const customStyles = {
    content: {
      zIndex: '9000',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '800px',
      padding: '',
      border: '0px',
      borderRadius: '20px',
      marginRight: '-50%',
      backgroundImage: 'radial-gradient( circle farthest-corner at 83.7% 4.3%,  rgba(19,19,27,1) 0%, rgba(9,9,14,1) 90% )',
      transform: 'translate(-50%, -50%)',
    },
  };
  
  return (
    <div className="w-full header cursor-pointer bg-black fixed top-0 z-50">
      <Modal
            isOpen={showAboutMe}
            onRequestClose={closeModal}
            overlayClassName="modal-overlay"
            style={customStyles}
      >
            <div className="flex-col modal-content flex pt-24 px-6 md:px-10 py-20 md:py-6 md:pt-10 text-white align-center justify-center">
              <div className="flex justify-between">
                <h2 className="text-4xl font-bold mb-10">About me</h2>
                <div className="flex gap-4">
                  <a href="https://twitter.com/OhYouBlockhead" target="_blank">
                    <TwitterIcon fontSize="large" className="cursor-pointer about-link"/>
                  </a>
                  <a href="https://www.amazon.com/stores/Blockhead/author/B07R8G58VC?ref=ap_rdr&store_ref=ap_rdr&isDramIntegrated=true&shoppingPortalEnabled=true" target="_blank">
                    <img className="cursor-pointer w-8 h-8 about-link" src={amazon}/>
                  </a>
                </div>
              </div>
              <div className="opacity-80">
                <p>
                  Blockhead is from Las Vegas. 
                </p>
                <p className="mt-4">
                  His family moved there when he was four when his nuclear engineer dad uprooted the family to be closer to the blackjack tables and his work at the Nevada Test Site.
                </p>
                <p className="mt-4">
                  Blockhead’s mother took inspiration from Eminem songs. Dealing with her own traumatic upbringing and severe mental illness, she relied on a rotation of substances and vices to get through raising a son and her job as a public-school teacher.
                </p>
                <p className="mt-4">
                  Blockhead was a mediocre professional poker player before leaving Vegas to obtain his law degree in the Midwest. He went to Los Angeles to pursue writing where won awards at a prestigious film school, worked at a top crypto start-up, and joined a professional blackjack team.
                </p>
              </div>
              <div className="w-100 flex mt-10 justify-center items-center">
                <a className="text-center btn-mint w-40 cursor-pointer" onClick={closeModal}>close</a>
              </div>
            </div>
      </Modal>
      <div className="header-content container text-white px-6 md:h-20 h-16 bg-black justify-between flex items-center">
        {useLocation().pathname != '/' &&
        <div className='text-white md:hidden text-xl'>
          <ArrowBackIosIcon onClick={(event) => {history(`/`)}}/>
        </div>
        }
        <h1 className={"md:text-3xl text-xl font-bold " + (useLocation().pathname == "/" && " w-full text-center md:w-auto md:text-left")}>
          <a href="/"><span className="text-highlight">BLOCKHEAD</span>.STUDIO</a>
        </h1>
        {
          useLocation().pathname != "/" && <div className="md:hidden"></div>
        }
        {
              !web3User ? (
                <div className="flex md:gap-4 items-center">
                  <div className="md:w-20 h-full ">
                    <a className="cursor-pointer hidden hover:opacity-50 md:block" onClick={openModal}>About Me</a>
                  </div>
                <button className="btn hidden md:block" onClick={() => connectWallet(false)}>
                  Connect Wallet
                </button>
                </div>
              ) : (
                <div className="flex md:gap-4 items-center">
                  <div className="md:w-20 h-full ">
                    <a className="cursor-pointer hidden hover:opacity-50 md:block" onClick={openModal}>About Me</a>
                  </div>
                  <div className="md:w-16 h-full ">
                    <a className="cursor-pointer hidden hover:opacity-50 md:block" onClick={signOut}>Sign Out</a>
                  </div>
                  <div className="btn hidden md:flex flex gap-2 items-center">
                    <Jazzicon diameter={30} seed={jsNumberForAddress(web3User)}/>
                    <p>{web3User.slice(0, 6) + '...' + web3User.slice(38, 42)}</p>
                  </div>
                  {/* <div className="md:hidden" onClick={(event) => {setShowHamburger(true)}}>
                    <Jazzicon diameter={32} seed={jsNumberForAddress(web3User)}/>
                  </div>
                  { showHamburger &&
                     <div className="hamburger absolute top-0 left-0 w-screen h-screen bg-black opacity-95 pt-6 px-6">
                        <div className="flex w-full gap-6 gap-2 justify-between items-center">
                          <div className="text-xl" onClick={(event) => {setShowHamburger(false)}}>
                            X
                          </div>
                          <div className="flex items-center gap-4">
                            <p className="text-xl">{web3User.slice(0, 8) + '...' + web3User.slice(35, 42)}</p>
                            <Jazzicon diameter={45} seed={jsNumberForAddress(web3User)}/>
                          </div>
                        </div>
                        <div onClick={signOut} className="w-full sign-out-hamburger mt-8 btn text-xl opacity-100">
                            <span>Sign out</span>
                        </div>
                     </div>
                  } */}
                 
                </div>
               
              )
            }
    </div>
    </div>
  )
}
