import React, {useContext, useState, useEffect} from 'react';
import Modal from 'react-modal';
import { AuthContext } from '../contexts/AuthContext'
import {ALCHEMY_KEY, NETWORK, CONTRACT_ADDRESS} from '../web3config'
import ABI from '../contract/ABI.json'
import axios from 'axios';
import {ethers} from 'ethers'
// const ethers = require("ethers");

const customStyles = {
    content: {
      zIndex: '9000',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '400px',
      padding: '',
      border: '0px',
      borderRadius: '20px',
      marginRight: '-50%',
      backgroundImage: 'radial-gradient( circle farthest-corner at 83.7% 4.3%,  rgba(19,19,27,1) 0%, rgba(9,9,14,1) 90% )',
      transform: 'translate(-50%, -50%)',
    },
  };

function MintModal({showStory, price, title, index}) {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [minted, setMinted] = useState(0);
    const [networkErr, setNetworkErr] = useState(false);
    const [mintErr, setMintErr] = useState('');
    const [loading, setLoading] = useState(false);
    const [txHash, setTxHash] = useState('');
    const [isOwner, setIsOwner] = useState(false);
    const [contract, setContract] = useState(null);

    const {web3User, firebaseUser, userNFTData, setUserNFTData} = useContext(AuthContext);

    const supply = 2000;
    const limit = 2000;

    var alchemyProvider = null

    function openModal() {
        getBalance();
        setIsOpen(true);
    }

    function afterOpenModal() {
    }

    async function verifyStory(index) {
        try {
            await axios.post(`${process.env.REACT_APP_CLOUDFUNCURL}/api/verifyStory`, 
            { address: web3User, storyNo: index });
            let stories = userNFTData.stories;
            stories[index.toString()] = true;
            setUserNFTData({
                ...userNFTData, stories
            })
            setLoading(false);
        } catch (err) {
            setNetworkErr(true);
            setMintErr('Failed to verify ownership. Please try logging in again.')
            setLoading(false);
        }
    }

    function handleClick() {
        if(canRead()){
            showStory(index);
        } else {
            openModal();
        }
    }

    function closeModal() {
        setIsOpen(false);
        setTxHash('');
    }

    useEffect(() => {

        if(window.ethereum) {
            window.ethereum.on('networkChanged', function(networkId){
                setNetworkErr('');
            });    
        }
       
        setNetworkErr('');
        setMintErr('');
        alchemyProvider = new ethers.providers.AlchemyProvider(NETWORK, ALCHEMY_KEY);
        let blockStudioContract = new ethers.Contract(CONTRACT_ADDRESS, ABI, alchemyProvider);
        setContract(blockStudioContract);
        // getMintedCount();
        // setInterval(()=> {
        //     getMintedCount()
        // }, 10000)
    }, [web3User])

    // const getMintedCount = async () => {
    //     let mintedNFTs = await goodGriefsContract.totalSupply();
    //     setMinted(mintedNFTs);
    // }

    const getBalance = async () => {
        let balance = await contract.balanceOf(web3User, index);
        if(+balance.toString() > 0) {
            setIsOwner(true);
        } else {
            setIsOwner(false);
        };
    }

    const handleMint = async (amount) => {
        setNetworkErr(false);
        
        setContract(new ethers.Contract(CONTRACT_ADDRESS, ABI, alchemyProvider));

        if(web3User) {
            let signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
            let fee = 0;
            try {
                fee = ethers.utils.parseEther(price.toString());
            } catch(err) {
                setNetworkErr(true);
                console.error(err)
                return;
            }
            
            contract.connect(signer).mint(index , {value: fee}).then(function(txn) {
                setLoading(true);
                txn.wait().then(async function(receipt) {
                    // getMintedCount();
                    setLoading(false);
                    if (receipt.status == 1) {
                        setLoading(true);
                        setTxHash(receipt.transactionHash);
                        verifyStory(index);
                    }
                    if (receipt.status == 0) {
                        alert("Error minting");
                        return;
                    }
                }).catch(function(err) {
                    setNetworkErr(true);
                    return;
                });
            }).catch(function(err) {
                setMintErr(err.reason);
                setNetworkErr(true);
                setLoading(false);
                // they cancelled.
                if (err.code == 4001) {
                    setNetworkErr(false);
                    return;
                }

                console.log(err);
                return;
            });
        }
    }

    function canRead() {
        if(price == 0) {
            return true;
        } 
        return userNFTData && (userNFTData.stories[index] || userNFTData.isGoodGriefHolder)
    }

  return (
    <div className="container">
        {(firebaseUser || price == 0)  &&
           <button onClick={ handleClick } className="btn-mint">{canRead() ? `Read` : `Mint for ${price}Ξ`}</button>
        }
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            overlayClassName="modal-overlay"
            style={customStyles}
            contentLabel="Example Modal"
            >
            <div className="flex-col modal-content flex pt-24 px-6 md:px-10 py-20 md:py-6 md:pt-10 text-white align-center justify-center">
                <h2 className="text-4xl font-bold mb-10">Get access to '{title}'</h2>
                {/* <h3 className="text-center text-xl">{`${minted}/${supply} SOLD`}</h3> */}

                <div className="flex-col gap-4 my-6">
                {/* <div className="flex text-l justify-between">
                    <p>Balance:</p>
                    <p>0.1 ETH</p>
                </div> */}
                <div className="flex text-l justify-between">
                    <p>Price:</p>
                    <p>{price} ETH</p>
                </div>
                </div>
                    { loading && <div className="loader">Loading...</div> }
                    {(isOwner && !txHash) && <p className="text-l pb-4">You already own this NFT. Please verify ownership on the main page.</p>}
                    { (txHash && !loading)  &&
                        <p className="text-l pb-4">Minting successful. See your transaction <a className="text-blue-400 underline text-lg" target="_blank" href={`https://etherscan.io/tx/${txHash}`}>HERE</a>.  Please verify ownership on the main page</p>} 
                    {/* { (txHash && !loading)  &&
                    <p className="text-lg">Minting successful. See your transaction <a className="text-blue-400 underline text-lg" href={`https://${NETWORK === 'mainnet' ? '' : NETWORK + '.'}etherscan.io/tx/${txHash}`}>HERE</a></p>} */}
                    {networkErr && 
                    <p className="text-center text-red-400 text-l mb-6 font-bold">
                        {mintErr ? mintErr : 'There seems to be a problem. Make sure you are connected to the right network and have enough Ξ. Check console for details.'}
                    </p>
                    }
                    <button className={'btn text-xl px-16 py-4 rounded-md mb-4 ' + ((isOwner || txHash) && 'opacity-30 pointer-events-none cursor-default ')} disabled={isOwner || txHash} onClick={() => handleMint(1)} >Mint now</button>
                    <a className="text-center cursor-pointer" onClick={closeModal}>close</a>
                    <a href="https://etherscan.io/address/0x5E8A578B58582a1CB9D447862859f5A0c303E675" target="_blank" className="mt-10 underline cursor-pointer text-center">Smart Contract</a>
            </div>
            </Modal>
    </div>
  )
}

export default MintModal